// COLORS
$color-primary: #4875f3;
$color-secondary: #fed230;
$color-tertiary: #2746b0;
$color-success: #7fba7a;
$color-warning: #fec22d;
$color-danger: #ff0000;

// TEXT
$text-default: #242425;
$text-secondary: #fff;
$text-label: #808291;
$text-disabled: #e1dfe9;

// SIZE
$sm1: 0.375rem;
$sm2: 0.625rem;
$sm3: 0.875rem;

/* MEDIUM */
$md1: 1rem;
$md2: 1.375rem;
$md3: 1.75rem;

/* LARGE */
$lg1: 2.5rem;
$lg2: 3.5rem;
$lg3: 4.5rem;

$shadow: 3px -3px 20px -2px $text-disabled;

$yellow-gradient: linear-gradient(
  90deg,
  rgba(255, 230, 137, 1) 0%,
  rgba(254, 210, 48, 1) 100%
);
$blue-gradient: linear-gradient(
  180deg,
  rgba(7, 69, 157, 1) 0%,
  rgba(72, 117, 243, 1) 100%
);

@mixin mq($from) {
  @media (max-width: $from) {
    @content;
  }
}
