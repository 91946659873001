.auth__page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafb;

  .auth__content {
    text-align: center;

    .auth__title {
      font-size: $lg1;
      color: $text-default;
      font-weight: 500;
      margin-bottom: $md2;
    }

    .auth__message {
      font-size: $sm3;
      color: $text-label;
    }

    .auth__card {
      background-color: white;
      border-radius: $md2;
      box-shadow: #cdcdd444 3px -5px 40px 0px;
      padding: $md3;
      margin: $lg1 0;
      width: 500px;
      text-align: initial;

      .auth__extras {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $md3;

        .forgot {
          font-size: $sm3;
          color: $color-primary;

          &:hover {
            cursor: pointer;
            color: $color-tertiary;
          }
        }
      }
    }

    .auth__caption {
      font-size: $sm3;
      color: $color-primary;
      transition: all 0.3s ease-in-out;

      &:hover {
        cursor: pointer;
        color: $color-tertiary;
      }
    }
  }
}
