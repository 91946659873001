.job__view {
  padding: $md1;

  .locations__list {
    padding-top: $md2;
    width: calc(100% + (20px + 1rem));
    display: flex;
    overflow: scroll hidden;
    margin-left: -1rem;
    // height: 15vh;
  }
}

.detail__view {
  width: 31%;
  height: 100vh;
  overflow: hidden scroll;
  background: white;
  box-shadow: 0 1rem 3rem rgba(225, 223, 233, 0.285);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  .hero {
    position: relative;
    height: 30vh;
    padding-bottom: 2rem;
    background-color: $text-disabled;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.2s ease;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &::after {
      content: '';
      position: absolute;
      // background-color: orange;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 10rem;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(36, 36, 37, 1) 100%
      );
    }

    h3 {
      font-size: $md2;
      font-weight: bold;
      color: white;
      margin: $sm1 0;
    }
    p {
      font-size: $md1;
      color: white;
      margin: 0;
    }

    .hero__content {
      position: relative;
      z-index: 2;
      padding: $md1;

      button {
        transition: all 0.2s ease;
      }
    }

    &.move .hero__content {
      .hero__caption,
      h3 {
        padding-left: 3rem;
      }
    }

    .hero__floatingBtn {
      position: absolute;
      z-index: 2;
      bottom: -1.25rem;
      right: 1.5rem;
      transition: all 0.5s ease;
    }

    &.fixed .hero__content {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 0 1rem;

      h3 {
        margin-bottom: 0.25rem;
        padding-left: 3rem;
      }
      .statusPill {
        display: none;
      }
      .hero__caption {
        font-size: 0.75rem;
        padding-left: 3rem;
        color: white;
        text-decoration: none;
      }
    }

    &.bg .hero__content {
      background-color: $color-tertiary;
    }
    &.floatBtn .hero__floatingBtn {
      position: fixed;
      top: 0.5rem;
    }

    .hero__nav {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .page__details {
    min-height: calc(100vh - 28vh);
    background-color: white;
    z-index: 1;
    position: relative;
    border-radius: 1rem 1rem 0 0;
    padding: 1rem;

    .description {
      overflow: hidden;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        // background-color: orange;
        z-index: 1;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 3rem;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) -10%,
          rgb(255, 255, 255) 100%
        );
      }

      &.expanded {
        // max-height: initial;

        &::after {
          content: unset;
        }
      }
    }

    .section__title {
      font-weight: 700;
      font-size: 1.09rem;
    }

    .read__more {
      font-size: 12px;
      color: $color-primary;

      &:hover {
        cursor: pointer;
        color: $color-tertiary;
      }
    }
  }
}
