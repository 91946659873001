.job__proof {
  height: 100vh;
  padding: $md1;

  .document__list {
    // background: orange;
    height: 100%;
    overflow-y: scroll;
  }

  .required__docs {
    height: 100%;
    position: relative;



    .docs__actions {
      position: absolute;
      bottom: $lg1;
      right: $lg1;
      display: flex;

      :first-child {
        margin-right: $md1;
      }
    }
  }
}

.upload__doc {
  margin-top: $md2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.progress {
  width: 100%;
  position: relative;
  border-radius: 10rem;
  height: 0.275rem;
  background-color: $text-disabled;
  overflow: hidden;

  .progress__bar {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10rem;
    background: $color-primary;
  }
}
