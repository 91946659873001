.profile__panel {
  width: 31%;
  height: 100vh;
  overflow: hidden scroll;
  background: white;
  box-shadow: 0 1rem 3rem rgba(225, 223, 233, 0.285);
  padding: $md1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}

.profile__edit {
  width: 31%;
  height: 100vh;
  overflow: hidden scroll;
  background: white;
  box-shadow: 0 1rem 3rem rgba(225, 223, 233, 0.285);
  padding: $md1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}

.address-dropdown {
  position: absolute;
  background-color: orange;
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid $text-disabled;
  border-radius: $md1;
  overflow: hidden;
  box-shadow: $shadow;
  z-index: 1;

  .address-dropdown__item {
    height: 50px;
    display: flex;
    align-items: center;
    padding: 1rem;
    color: $text-default;

    .address-dropdown__item__address {
      font-weight: bold;
      // overflow: hidden;

      span {
        color: $text-label;
        font-weight: lighter;
        font-size: 0.775rem;
      }
    }

    .dropdown__icon {
      margin: 0 0.375rem 0 0;
      width: 22px;
      height: 22px;
    }

    &--active {
      background-color: $color-primary;
      color: white !important;
      cursor: pointer;

      .address-dropdown__item__address {
        color: white;
      }
    }
  }
}

.edit__avatar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;

  .profile__avatar {
    position: relative;
    border-radius: 50%;
    width: 50%;
    height: auto;
    padding-top: 50%;
    background: #eeeeee;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 2rem;
    text-align: center;

    ion-icon {
      position: absolute;
      top: calc(50% - 2.25rem);
      left: calc(50% - 2.25rem);
      font-size: $lg3;
      color: $text-label;
    }
  }

  button {
    width: fit-content;
  }

  .document__upload {
    font-size: $sm3;
    color: $text-label;

    p {
      margin: $sm3 0;
    }
  }
}
