@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700;900&display=swap');

html {
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  font-family: 'Rubik', sans-serif;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
body,
pre {
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  // font-size: inherit;
  font-weight: inherit;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

button {
  -webkit-appearance: none;
  -webkit-border-radius: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.google-map * {
  border-style: none;
}

*,
::before,
::after {
  border-width: 0;
  border-style: solid;
  border-color: theme('borderColor.default', currentColor);
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}
