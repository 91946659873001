.section {
  margin-bottom: $sm2;

  .section__title {
    font-size: $md3;
    font-weight: bold;
    color: $text-default;
  }
  .section__footer {
    color: $text-label;
    font-size: $sm3;
  }

  &--small {
    .section__title {
      font-size: $md1;
      font-weight: bold;
      color: $text-default;
    }
    .section__footer {
      color: $text-label;
      font-size: $sm3;
    }
  }
}

.tabs {
  padding-top: $sm3;
  display: flex;

  .tab {
    text-transform: capitalize;
    text-decoration: none;
    color: $text-default;
    font-size: $md1;
    transition: all 0.3s ease-in-out;
    margin-right: $md1;

    &--active {
      font-weight: 500;
      color: $color-primary;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $color-tertiary;
    }
  }
}

.tabs__content {
  padding-top: $sm2;
}

.diggerCard__wrapper {
  // width: calc(100% - 2rem);
  width: 100%;
  box-shadow: $shadow;
  border-radius: $md2;
  background: $yellow-gradient;
  border: 1px solid $text-disabled;
  padding: $md1;
  position: relative;
  overflow: hidden;
  color: white;

  .circle {
    width: 12rem;
    height: 12rem;
    border: 1rem solid $text-default;
    border-radius: 100%;
    position: absolute;
    top: -3rem;
    right: -3rem;
    z-index: 0;
    opacity: 0.1;

    &.left {
      width: 5rem;
      height: 5rem;
      top: 9rem;
      left: -2rem;
      border: 0.3rem solid $text-default;
    }
  }

  .diggerCard__title {
    text-transform: capitalize;
    margin: $md1 0;
    span {
      font-weight: normal;
    }
  }

  .diggerCard__details {
    display: flex;
    align-items: center;
    position: relative;
    // z-index: 1;

    img {
      width: 3rem;
      height: 3rem;
      object-fit: cover;
      background-color: $text-disabled;
      border-radius: 100%;
      margin-right: $md1;
    }

    h3 {
      font-size: $md2;
      font-weight: 500;
      margin: $md1 0;
    }
  }

  .diggerCard__location {
    display: flex;
    align-items: center;
    color: $text-label;
    color: white;

    p {
      display: flex;
      align-items: center;
      font-size: 0.8rem;
      margin: 0.875rem 0px 0.2rem;
      font-weight: bold;

      span {
        font-weight: normal;
      }
    }

    svg {
      width: 18px;
      height: 18px;
      fill: $text-default;
      margin-right: 1rem;
    }
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: $text-disabled;
  margin: $md1 0;
}

.alert__wrapper {
  max-width: 28rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  display: flex;
  border-radius: 0.5rem;
  background-color: white;
  position: fixed;
  top: 80px;
  width: calc(100% - 2rem);
  overflow: hidden;
  z-index: 5;

  .alert__accent {
    width: 0.5rem;
    background-color: #fed230;
  }

  .alert__content {
    display: flex;
    align-items: center;
    padding: $sm3 0;
    width: 100%;
    // padding: .75rem .5rem;
    // display: flex;
    // align-items: center;

    .alert__icon {
      margin-left: $sm3;
      width: $lg2;
      height: $lg2;
      border-radius: 100%;
      background-color: #fed1302f;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: $md2;
        height: $md2;
        fill: $text-default;
      }
    }

    .message__wrapper {
      margin: 0 0.75rem;

      .alert__title {
        font-size: 1.25rem;
        color: $text-default;
        font-weight: bold;
        margin: 0;
      }

      .alert__message {
      }
    }
  }

  p {
    margin: 0;
  }
}

.skill__input,
.skill__slider {
  margin: $md1 0;
}
.progressBar {
  width: 100%;
  position: relative;
  border-radius: $md3;
  height: $sm1;
  background-color: $text-disabled;
  overflow: hidden;
  margin-top: $lg1;
  margin-bottom: $lg2;

  .progressBar__inner {
    height: 100%;
    width: 50%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: $md3;
    background: $color-primary;
  }
}
