.button,
a.button {
  min-width: fit-content;
  max-width: 640px;
  height: $lg1;
  background-color: $color-primary;
  transition: all 0.3s ease-in-out;
  border-radius: $sm2;
  font-weight: 500;
  font-size: $sm3;
  padding: 0 $md2;
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  &:disabled {
    background: #e6e6e6;
    cursor: not-allowed;
    color: $text-default;

    &:hover {
      cursor: not-allowed;
      color: white;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: $color-tertiary;
  }

  &__primary {
    background-color: $color-primary;
    color: white;

    &:hover {
      cursor: pointer;
      background-color: $color-tertiary;
    }
  }

  &__secondary {
    background: $text-disabled;
    color: $text-default;

    &:hover {
      cursor: pointer;
      background-color: $text-label;
      color: white;
    }
  }

  &--full {
    width: 100%;
  }

  &--above {
    margin-bottom: $md1;
  }

  &--disabled {
    background: #e6e6e6;
    cursor: not-allowed;
    color: $text-default;

    &:hover {
      cursor: not-allowed;
      color: white;
    }
  }

  &--withIcon {
    justify-content: space-between;
  }
}
