.location__card {
  max-width: 15rem;
  text-decoration: none;
  color: inherit;
  margin-right: $lg2;

  &:first-child {
    margin-left: $md2;
  }

  .location__address {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: $sm3;
    color: $text-label;
  }

  .location__title {
    font-weight: bold;
    margin: $sm2 0;
    overflow: hidden;
    font-size: $md2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  .location__img {
    width: 15rem;
    height: 15rem;
    max-width: 15rem;
    max-height: 15rem;
    border-radius: $md1;
    overflow: hidden;
    // box-shadow: $shadow;
    object-fit: contain;
    background-color: white;
    box-shadow: 3px -3px 20px -2px $text-disabled;
  }

  &.location__card--horizontal {
    margin: 1rem 1rem 0 0;

    &:first-child {
      margin-left: $md1;
    }
  }
}
