.onboarding {
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  padding: $lg1;
  position: relative;

  .onboarding__form {
    width: 70%;
  }

  .section__title {
    font-size: $md1;
    font-weight: bold;
    margin: $md1 0;
  }

  .onboarding__actions {
    position: absolute;
    bottom: $lg3;
    right: $lg3;
    display: flex;

    button {
      margin-right: $md1;
    }
  }

  h1 {
    font-weight: bold;
    font-size: $md3;
  }
  .caption {
    margin-top: $md1;
    width: 85%;
    text-align: center;
    font-size: $sm3;
    color: $text-label;
  }

  .greeting {
    text-align: center;

    h3 {
      font-weight: bold;
      font-size: $md2;
      margin-bottom: $sm1;
    }

    p {
      font-size: $md1;
      color: $text-label;
    }

    .illustration {
      margin: $md3 auto;
      max-width: 30vw;
      // max-height: 250px;
      object-fit: contain;
    }
  }

  .experience {
    .add__skill {
      margin: 0 auto;
    }
  }
}

.skillInput {
  position: relative;
  width: 100%;
}
