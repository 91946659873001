.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 92px;
  background-color: white;
  height: 100vh;
  padding: 1.5rem 1.5rem 2rem;
  transition: 0.3s ease-in-out;
  z-index: 100;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    height: calc(100% - (1.5rem + 2rem));
    margin-top: calc((1.5rem + 2rem) * 0.5);
    background: $text-disabled;
  }

  &--open {
    width: calc(92px + 9.25rem);
  }

  &--hidden {
    width: 0;
    display: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .nav__brand {
    display: grid;
    grid-template-columns: max-content max-content;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .nav__toggle {
      font-size: 1.25rem;
      padding: 0.75rem;
      cursor: pointer;
    }

    .logo {
      color: white;
      font-weight: 600;
    }
  }

  nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;

    .nav__link {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      column-gap: 0.75rem;
      padding: 0.75rem;
      color: $text-default;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: $color-primary;
        color: white;
      }

      .nav__icon {
        font-size: 1.35rem;
      }

      .nav__name {
        margin-left: 0.375rem;
        font-size: 0.875rem;
      }

      &--active {
        background-color: $color-primary;
        color: white;
      }
    }
  }
}
