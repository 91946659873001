.list__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: $md1;
  position: relative;

  &.withPadding {
    padding: 1rem;
  }

  .list__actions {
    position: absolute;
    height: 3.3rem;
    width: 100%;
    display: flex;
    // align-items: flex-end;
    justify-content: flex-end;
    align-items: center;
    right: 0;
    // top: $page__padding;
    top: 0;
  }

  .list__item {
    width: 100%;
    min-height: 3.3rem;
    padding: 0.5rem;
    border-radius: $md1;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    position: relative;
    // background-color: #fafafa;

    &.--white {
      background-color: white;
    }

    &.noMargin {
      margin-bottom: 0;
    }

    .item__accent {
      width: 9px;
      height: 32px;
      margin-right: 31px;
      border-radius: 9px;
      background-color: $color-primary;

      &.yellow {
        background-color: $color-warning;
      }
      &.green {
        background-color: $color-success;
      }
      &.blue {
        background-color: $color-primary;
      }
      &.darkblue {
        background-color: $color-tertiary;
      }
      &.black {
        background-color: $text-default;
      }
      &.gray {
        background-color: $text-label;
      }
    }

    .item__icon {
      width: $lg1;
      height: $lg1;
      background-color: #4876f313;
      border-radius: $md1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $md1;
      font-size: $md1;
      color: $color-primary;
    }
    .item__content {
      font-weight: bold;
      font-size: $md1;
    }

    .list__caption {
      font-weight: normal;
      font-size: $sm3;
      color: $text-label;
    }

    &.border {
      border: 1px solid #e6e6e6;
    }
  }
  .item__close {
    position: absolute;
    width: 100%;
    height: 3rem;
    background-color: red;
    z-index: -1;
  }
}
