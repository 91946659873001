// GLOBAL
@import './1.global/_reset';
@import './1.global/_variables';
@import './1.global/_responsive';

// PAGES
@import './2.pages/_login';
@import './2.pages/_jobs';
@import './2.pages/_profile';
@import './2.pages/_chat';
@import './2.pages/_proof';
@import './2.pages/onboarding';

// LAYOUTS
@import './3.layouts/_sidebar';
@import './3.layouts/_jobCard';
@import './3.layouts/_locationCard';
@import './3.layouts/_misc';
@import './3.layouts/_modal';
@import './3.layouts/multiStep';

// BLOCKS
@import './4.blocks/_button';
@import './4.blocks/_input';
@import './4.blocks/_notifications';
@import './4.blocks/_misc';
@import './4.blocks/_list';
@import './4.blocks/editor';

.app {
  padding-left: 92px;
  transition: 0.3s ease-in-out;

  &--open {
    padding-left: calc(92px + 9.25rem);
  }

  &--full {
    padding-left: 0;
  }
}
