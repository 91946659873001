.layout__button {
  background-color: white;
  height: 9rem;
  border-radius: $md1;
  box-shadow: $shadow;
  padding: $md1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.3rem;
  position: relative;

  .layoutButton__content {
    h4 {
      font-size: $md2;
      font-weight: bold;
    }

    p {
      margin-top: $sm1;
      font-weight: normal;
      font-size: $md1;
      color: $text-label;
    }

    .layoutButton__caption {
      &.smaller {
        font-size: 0.775rem;
      }
    }
  }
}

.layoutButton__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4876f315;
  width: fit-content;
  width: 2.7rem;
  height: 2.7rem;
  border-radius: 100%;
  font-size: $md1;
  color: $color-primary;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  position: relative;
  padding-right: 15px;
  height: 100%;

  &--centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

:root {
  --card-line-height: 1.2em;
  --card-padding: 1em;
  --card-radius: 0.5em;
  --color-green: $color-primary;
  --color-gray: #e2ebf6;
  --color-dark-gray: #c4d1e1;
  --radio-border-width: 2px;
  --radio-size: 1.5em;
}

.card {
  background-color: #fff;
  border-radius: var(--card-radius);
  position: relative;

  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  }
}

.radio {
  font-size: inherit;
  margin: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
  // right: calc(var(--card-padding) + var(--radio-border-width));
  // top: calc(var(--card-padding) + var(--radio-border-width));
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: #fff;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: 50%;
    cursor: pointer;
    height: var(--radio-size);
    outline: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
    width: var(--radio-size);

    &::after {
      border: var(--radio-border-width) solid #fff;
      border-top: 0;
      border-left: 0;
      content: '';
      display: block;
      height: 0.75rem;
      left: 25%;
      position: absolute;
      top: 50%;
      transform: rotate(45deg) translate(-50%, -50%);
      width: 0.375rem;
    }

    &:checked {
      background: $color-primary;
      border-color: $color-primary;
    }
  }

  .card:hover .radio {
    border-color: var(--color-dark-gray);

    &:checked {
      border-color: $color-primary;
    }
  }
}

.plan-details {
  border: var(--radio-border-width) solid var(--color-gray);
  border-radius: var(--card-radius);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: var(--card-padding);
  transition: border-color 0.2s ease-out;
}

.card:hover .plan-details {
  border-color: var(--color-dark-gray);
}

.radio:checked ~ .plan-details {
  border-color: $color-primary;
}

.radio:focus ~ .plan-details {
  box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled ~ .plan-details {
  color: var(--color-dark-gray);
  cursor: default;
}

.radio:disabled ~ .plan-details .plan-type {
  color: var(--color-dark-gray);
}

.card:hover .radio:disabled ~ .plan-details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.card:hover .radio:disabled {
  border-color: var(--color-gray);
}

.plan-type {
  color: $color-primary;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1em;
}

.plan-cost {
  font-size: 2.5rem;
  font-weight: bold;
  padding: 0.5rem 0;
}

.slash {
  font-weight: normal;
}

.plan-cycle {
  font-size: 2rem;
  font-variant: none;
  border-bottom: none;
  cursor: inherit;
  text-decoration: none;
}

.hidden-visually {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
