.chat__list {
  background: white;
  width: 100%;
  height: 100vh;
  position: sticky;
  padding: $md1;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100vh;
    background: $text-disabled;
  }
}

.channel {
  width: 100%;
  display: flex;
  // align-items: center;
  color: $text-default;
  text-decoration: none;
  margin-bottom: 1rem;
  position: relative;
  border-radius: $md1;
  padding: $md2 $sm2;

  &--active {
    background: #4876f30e;
    border: 1px solid #4876f337;
  }

  &--noWidth {
    width: fit-content;
  }

  &--noBorder {
    &::after {
      display: none;
    }
  }

  &--noMargin {
    margin: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background: $text-disabled;
  }

  .channel__status {
    position: absolute;
    top: 0;
    right: 0;
  }

  .channel__img {
    width: $lg1;
    height: $lg1;
    background-color: $text-disabled;
    border-radius: 100%;
    margin-right: 1rem;
  }

  .chat__details {
    width: 70%;
    overflow: hidden;

    .channel__lastSentName {
      font-size: $sm2;
      color: $color-primary;
    }

    .channel__title {
      font-weight: bold;
      font-size: $md1;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      margin-bottom: $sm1;
    }

    .channel__message {
      font-size: $sm3;
      color: $text-label;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}

.chat__open {
  // width: calc(100% - $md1);
  height: 100vh;
  padding: $md2 $md2 $md2 0;

  .chat__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: $md3;
    position: relative;

    .header__actions {
      display: flex;
      align-items: center;

      button {
        margin-left: $md1;
      }
    }

    &::after {
      content: '';
      width: calc(100% - 1.75rem);
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      position: absolute;
      background: $text-disabled;
    }
  }
}

.message__list {
  width: 100%;
  padding-right: $md3;
  margin-top: $md2;
  display: flex;
  flex-direction: column-reverse;
  overflow: scroll;
}

.message {
  margin-top: 1rem;
  padding-left: 65px;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  padding-right: 48px !important;
  position: relative;

  .messageContent {
    img {
      position: absolute;
      left: 16px;
      top: 4px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    h3 {
      margin: 0;
    }
    .messageSender {
      font-weight: bold;
      font-size: 0.875rem;

      span {
        font-size: 0.65rem;
        line-height: 1.375rem;
        color: $text-label;
        font-weight: lighter;
        vertical-align: baseline;
        margin-left: 0.25rem;
      }
    }

    p {
      user-select: text;
      overflow: hidden;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
  }

  .messageText {
    font-size: 0.75rem;
  }

  .messageDocs {
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 0.25rem;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    margin-top: 1rem;

    .docWrapper {
      border-left: 4px solid $color-secondary;
      background: rgba(221, 221, 221, 0.144);
      align-self: start;
      justify-self: start;
      max-width: 432px;
      width: 100%;
      border-radius: 3px;
      overflow: hidden;

      .content {
        width: 100%;
        padding: 0.5rem 1rem 1rem 0.75rem;
        display: inline-grid;
        grid-template-columns: auto;
        grid-template-rows: auto;

        .platform {
          font-size: 0.75rem;
          line-height: 1rem;
          font-weight: 400;
          grid-column: 1/1;
          margin-top: 8px;
        }

        .title {
          font-weight: 600;
          display: inline-block;
          grid-column: 1/1;
          margin-top: 8px;
          color: $color-primary;
        }

        .video {
          width: 100%;
          padding-top: 56.25%;
          margin-top: 16px;
          grid-column: 1/1;
          border-radius: 4px;
          contain: paint;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        .message__actions {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.message__log {
  text-align: center;
  color: $text-label;
  font-size: 10px;
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.noSpace {
    text-align: left;
    padding: 0;
    margin-left: 1rem;
    margin-bottom: 0;
  }

  &.bigger {
    font-size: 14px;
  }
}

.chat__input {
  width: 100%;
  background: #e1dfe950;
  border-radius: $md1;
  padding: $md2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .input__wrapper {
    flex-grow: 1;
  }

  input {
    background: transparent;
    color: $text-default;
    border: none;
  }

  .chat__actions {
    button {
      margin-left: $md1;
    }
  }
}
