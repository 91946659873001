.modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 101;
  backdrop-filter: blur(8px) contrast(0.9);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal__content {
    height: 90vh;
    overflow: scroll;
    padding: $md2;
    background: white;
    border-radius: $sm3;
    width: 70vw;

    @include mq(992px) {
      width: 85vw;
    }

    @include mq(768px) {
      width: 100vw;
    }
  }

  &--small {
    .modal__content {
      width: 530px;

      @include mq(768px) {
        margin: 8px auto;
        max-width: calc(100vw - 16px);
      }
    }
  }
}
