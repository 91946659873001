.job__card {
  width: 100%;
  // background-color: rgba(128, 128, 128, 0.114);
  background-color: white;
  border-radius: $md1;
  height: calc(4.5rem * 2.5);
  overflow: hidden;
  color: $text-default;
  position: relative;
  padding: $md1;
  box-shadow: 0 1rem 3rem $text-disabled;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  &--horizontal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .job__status {
      position: absolute;
      top: $md1;
      right: $md3;
    }

    .job__image {
      width: $lg3;
      height: $lg3;
      border-radius: 50%;
      margin-right: $md1;
    }
  }

  .match__score {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $sm2;
    color: white;
    padding: $sm2;
    border-radius: 0 0 0 $sm3;
    top: 0;
    right: 0;

    &.green {
      background-color: $color-success;
    }

    &.blue {
      background-color: $color-primary;
    }

    &.yellow {
      background-color: $color-warning;
    }

    &.red {
      background-color: $color-danger;
    }
  }

  .location__details {
    width: 100%;
    height: $lg1;
    display: flex;
    align-items: center;

    .location__title {
      width: 100%;
      margin: 0px 0 0 $sm3;
      font-size: $sm3;
    }

    .location__image {
      width: $md3;
      height: $md3;
      min-width: $md3;
      min-height: $md3;
      object-fit: cover;
      border-radius: 50%;
      background-color: lightgray;
    }
  }

  .job__details {
    .job__title {
      font-size: $md1;
      font-weight: 500;
      margin: $sm2 0 0 0;
    }

    .job__meta {
      display: flex;
      align-items: center;
      color: $text-label;

      .icon {
        margin-right: $sm1;
        color: $color-warning;
      }

      .job__location,
      .job__salary {
        display: flex;
        align-items: center;
        font-size: $sm3;
        margin: $sm3 0px $sm1;
      }

      .job__salary {
        margin-left: $md3;
      }
    }
  }
}
