input,
select {
  width: 100%;
  height: 48px;
  border: 1px solid $text-disabled;
  border-radius: $sm2;
  background-color: white;
  color: $text-label;
  font-size: $sm3;
  padding: 0 $sm3;
  position: relative;

  &.above {
    margin-bottom: $md2;
  }
}

.input__error {
  font-size: $sm2;
  color: $color-danger;
}

.checkbox__inline {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: $sm3;
  color: $text-label;
  position: relative;

  input {
    margin-right: $sm2;
    height: $sm3;
    width: $sm3;
    position: relative;
  }
}

.input__wrapper {
  position: relative;

  .input__error {
    position: absolute;
    bottom: $sm1;
    left: $sm1;
  }
}

.label {
  font-size: $sm3;
  color: $text-label;
  margin-bottom: $sm3;
}

.skill__loader {
  position: absolute;
  right: 0;
  top: 0;
}
